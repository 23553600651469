import React, {lazy, useEffect, useRef, useState} from "react";
import {DialingCodes} from "./DialingCodes";
import Cookies from 'js-cookie';
import {useLocation, useNavigate} from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import {getCookieConsentValue} from "react-cookie-consent";
import FacebookLogin from "../FacebookLogin/FacebookLogin";

const GoogleSignInButton = lazy(() => import("../GoogleSignInButton"));


export const Signup = () => {
    const [hasAccepted, setHasAccepted] = useState(false);

    useEffect(() => {
        setHasAccepted(getCookieConsentValue('cookieConsent') === "true" || false)
    }, []);
    const [recaptchaError, setRecaptchaError] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null);
    const [signupStage, setSignupStage] = useState(1);
    const phoneRef = useRef(null);
    const dialRef = useRef(null);
    const verificationRef = useRef(null);
    const [phoneError, setPhoneError] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [dialcode, setDialCode] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [sentto, setSentto] = useState(null);
    const [submittingCode, setSubmittingCode] = useState(false);
    const [submittingError, setSubmittingError] = useState("");
    const navigate = useNavigate();
    const xlocation = useLocation();
    let path = "SIGN UP";
    if (xlocation.pathname === "/login") {
        path = "LOGIN";
    }
    const queryParams = new URLSearchParams(xlocation.search);
    const gamesession = queryParams.get("gamesession") || null;
    const coins = queryParams.get("coins") || null;
    const game = queryParams.get("game") || null;
    const deeplink = queryParams.get("back");

    function onChange(value) {
        setCaptchaValue(value);
    }

    const handleSubmit = async () => {
        setPhoneError("");
        const phone = phoneRef.current.value;
        const dial = dialRef.current.value;
        setPhoneNumber(phone);
        setDialCode(dial);
        if (phone === "") {
            setPhoneError("Please enter your phone number.");
        } else if (!captchaValue) {
            setPhoneError("Error verifying reCaptcha.");
        } else {
            setSubmitting(true);
            const headers = {
                "Content-Type": "application/json",
            };
            headers["x-api-key"] = process.env.API_KEY;
            const response = await fetch(`${process.env.API_URL}/otp/send`, {
                method: "post",
                headers: headers,
                body: JSON.stringify({
                    dialcode: dial,
                    phonenumber: phone,
                    captchaValue: captchaValue,
                }),
            });
            if (response.status === 200) {
                const data = await response.json();
                setSentto(data.sento);
                setSignupStage(3);
                setSubmitting(false);
            } else {
                setPhoneError(
                    "Error sending to phone number, please check entered number."
                );
                setSubmitting(false);
            }
        }
    };

    const handleSubmitCode = async () => {
        setSubmittingError("");
        const verifycode = verificationRef.current.value;
        if (verifycode === "") {
            setSubmittingError("Please enter your code.");
        } else {
            setSubmittingCode(true);
            const headers = {
                "Content-Type": "application/json",
            };
            headers["x-api-key"] = process.env.API_KEY;
            const response = await fetch(`${process.env.API_URL}/otp/verify`, {
                method: "post",
                headers: headers,
                body: JSON.stringify({sentto: sentto, verifycode: verifycode}),
            });
            if (response.status === 200) {
                const data = await response.json();
                await new Promise((resolve) => {
                    Cookies.set('c8_session_token', data.token, { expires: 365, secure: false, sameSite: 'strict' });
                    if (hasAccepted) {
                        TagManager.dataLayer({
                            dataLayer: {
                                user_id: data.userId,
                            },
                        });
                        Cookies.set('cr8_userId', true);
                    }
                    resolve();
                });
                if (gamesession !== null) {
                    await fetch(
                        `${process.env.API_URL}/collectwinnings/${gamesession}/${data.token}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                "x-api-key": process.env.API_KEY,
                            },
                        }
                    );
                }
                if (data.newAccount) {
                    if (deeplink === null) {
                        window.location.href = "/account_otp_newsignup";
                    } else {
                        window.location.href = "/account_otp_newsignup?back=" + deeplink;
                    }
                } else {
                    if (deeplink === null) {
                        window.location.href = "/account";
                    } else {
                        window.location.href = deeplink;
                    }
                }
            } else {
                setSubmittingCode(false);
                setSubmittingError(
                    "Verification failed. Please check code and try again."
                );
            }
        }
    };
    useEffect(() => {
        const updatePageTitle = () => {
            document.title = "ACCOUNT SIGNUP | Cricket8";
        };
        updatePageTitle();
        return () => {
        };
    }, []);
    return (
        <div id="content" className="min-h-[75vh] mt-[0px] md:mt-[20px]">
            {signupStage === 1 ? (
                <div id="stage1">
                    <div className="w-full flex">
                        <div className="flex-grow">
                            <h1 className="text-[30px] md:text-[55px] !text-lime">{path}</h1>
                        </div>
                        <div className="text-right">
                            <div className="font-chakra text-white">
                                {path !== "LOGIN" ? (
                                    <>Already registered?</>
                                ) : (
                                    <>Don't have an account?</>
                                )}
                            </div>
                            <div className="font-chakra text-lime">
                                {path !== "LOGIN" ? (
                                    <>
                                        <a className="text-white hover:underline" href="/login">
                                            Login here...
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <a className="text-white hover:underline" href="/signup">
                                            Sign up here...
                                        </a>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {gamesession ? (
                        <div className="mt-[20px] text-white font-chakra text-normal normal-case text-[14px]">
                            Create an account or login to store your coins for when C8 Trader
                            launches.
                        </div>
                    ) : null}

                    <div
                        className="mt-[30px] bg-[#1a73e8] h-[50px] w-full md:w-[600px] flex justify-center cursor-pointer pt-[5px]">
                        <GoogleSignInButton deeplink={deeplink} gamesession={gamesession} game={game} coins={coins}/>
                    </div>
                </div>
            ) : signupStage === 2 ? (
                <div id="stage2">
                    <div
                        className="flex h-[20px] cursor-pointer"
                        onClick={() => setSignupStage(1)}
                    >
                        <div className="bg-lime w-[20px] text-main pl-[2px] pt-[2px]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="m7.25 3.75l-4.5 4.5l4.5 4.5m6-4.5H2.75"
                                />
                            </svg>
                        </div>
                        <div className="bg-lime ml-[3px] pt-[3px] px-[10px] text-anton text-main text-[14px]">
                            BACK
                        </div>
                    </div>
                    <div className="mt-[30px]">
                        <h1 className="text-[30px] !text-lime">
                            SIGN UP WITH PHONE NUMBER (OTP)
                        </h1>
                    </div>
                    <div className="mt-[30px] font-chakra text-white text-[14px]">
                        Enter your phone number and we'll send you a code by SMS.
                    </div>

                    <div className="mt-[30px] w-full md:w-[600px] pb-[20px] diagonal-gradient p-[15px]">
                        <div className="text-white font-anton text-[16px]">
                            ENTER YOUR PHONE NUMBER
                        </div>
                        <div className="mt-[15px] flex">
                            <div>
                                <DialingCodes ref={dialRef}/>
                            </div>
                            <div className="flex-grow pl-[5px]">
                                <div className="w-full h-[40px] bg-[#fff] border-none mt-[1px]">
                                    <input
                                        ref={phoneRef}
                                        className="enteremail !text-main !pl-[10px]"
                                        type="text"
                                        placeholder="Enter phone number..."
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-[20px]">
                            <ReCAPTCHA
                                sitekey="6LcNubcpAAAAADIenWKHvGywBH0hNAL55T9yrqL8"
                                onChange={onChange}
                            />
                            {recaptchaError && (
                                <div
                                    style={{
                                        color: "#f73261",
                                        paddingTop: "5px",
                                        paddingLeft: "20px",
                                    }}
                                >
                                    {recaptchaError}
                                </div>
                            )}
                        </div>

                        {!submitting ? (
                            <div
                                className="mt-[15px] bg-lime w-full h-[50px] text-main pt-[15px] font-anton text-[18px] text-center cursor-pointer"
                                onClick={handleSubmit}
                            >
                                SUBMIT PHONE NUMBER
                            </div>
                        ) : (
                            <div className="h-[50px] pt-[29px] font-chakra text-white text-[14px] text-center">
                                Submitting...
                            </div>
                        )}
                        {phoneError && (
                            <div
                                style={{
                                    color: "#f73261",
                                    paddingTop: "10px",
                                    paddingLeft: "0px",
                                }}
                            >
                                {phoneError}
                            </div>
                        )}
                    </div>
                </div>
            ) : signupStage === 3 ? (
                <div id="stage3">
                    <div
                        className="flex h-[20px] cursor-pointer"
                        onClick={() => setSignupStage(2)}
                    >
                        <div className="bg-lime w-[20px] text-main pl-[2px] pt-[2px]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="m7.25 3.75l-4.5 4.5l4.5 4.5m6-4.5H2.75"
                                />
                            </svg>
                        </div>
                        <div className="bg-lime ml-[3px] pt-[3px] px-[10px] text-anton text-main text-[14px]">
                            BACK
                        </div>
                    </div>
                    <div className="mt-[30px]">
                        <h1 className="text-[30px] !text-lime">
                            SIGN UP WITH PHONE NUMBER (OTP)
                        </h1>
                    </div>
                    <div className="mt-[30px] w-full md:w-[600px] pb-[20px] diagonal-gradient p-[15px]">
                        <div className="text-white font-anton text-[16px]">
                            ENTER VERIFICATION CODE
                        </div>
                        <div className="mt-[15px] text-white font-chakra text-[12px]">
                            We've sent a verification code to {dialcode} {phonenumber}.
                        </div>
                        <div className="mt-[15px]">
                            <div className="w-full">
                                <div className="w-full h-[40px] bg-[#fff] border-none mt-[1px]">
                                    <input
                                        ref={verificationRef}
                                        className="enteremail !text-main !pl-[10px]"
                                        type="text"
                                        placeholder="Enter verification code..."
                                    />
                                </div>
                            </div>
                        </div>
                        {!submittingCode ? (
                            <div
                                className="mt-[15px] bg-lime w-full h-[50px] text-main pt-[15px] font-anton text-[18px] text-center cursor-pointer"
                                onClick={handleSubmitCode}
                            >
                                SUBMIT CODE
                            </div>
                        ) : (
                            <div className="h-[50px] pt-[29px] font-chakra text-white text-[14px] text-center">
                                Checking code...
                            </div>
                        )}
                        {submittingError && (
                            <div
                                style={{
                                    color: "#f73261",
                                    paddingTop: "10px",
                                    paddingLeft: "0px",
                                }}
                            >
                                {submittingError}
                            </div>
                        )}
                    </div>
                    <div className="mt-[30px] flex">
                        <div className="text-white font-chakra text-[14px]">
                            Didn't get a code?&nbsp;
                        </div>
                        <div
                            className="text-lime font-chakra text-[14px] cursor-pointer"
                            onClick={() => setSignupStage(2)}
                        >
                            Try again
                        </div>
                    </div>
        </div>
            ) : null}
            <div>
                <FacebookLogin
                    gamesession={gamesession} game={game} coins={coins} deeplink={deeplink}
                    name={
                        path === "SIGN UP"
                            ? "SIGN UP WITH FACEBOOK"
                            : "LOG IN WITH FACEBOOK"}
                />
            </div>


            {/*<div */}
            {/*    className='w-full md:w-[600px] mt-[5px] text-[20px] flex flex-row justify-center items-center bg-[#4267B2] h-[50px] cursor-pointer gap-2'*/}
            {/*    onClick={() => location.href='account_otp_newsignup'}*/}
            {/*>*/}
            {/*    {path} WITH TEXT*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="white">*/}
            {/*      <path d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3c0 0 0 0 0 0c0 0 0 0 0 0s0 0 0 0s0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM96 212.8c0-20.3 16.5-36.8 36.8-36.8l19.2 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-19.2 0c-2.7 0-4.8 2.2-4.8 4.8c0 1.6 .8 3.1 2.2 4l29.4 19.6c10.3 6.8 16.4 18.3 16.4 30.7c0 20.3-16.5 36.8-36.8 36.8L112 304c-8.8 0-16-7.2-16-16s7.2-16 16-16l27.2 0c2.7 0 4.8-2.2 4.8-4.8c0-1.6-.8-3.1-2.2-4l-29.4-19.6C102.2 236.7 96 225.2 96 212.8zM372.8 176l19.2 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-19.2 0c-2.7 0-4.8 2.2-4.8 4.8c0 1.6 .8 3.1 2.2 4l29.4 19.6c10.2 6.8 16.4 18.3 16.4 30.7c0 20.3-16.5 36.8-36.8 36.8L352 304c-8.8 0-16-7.2-16-16s7.2-16 16-16l27.2 0c2.7 0 4.8-2.2 4.8-4.8c0-1.6-.8-3.1-2.2-4l-29.4-19.6c-10.2-6.8-16.4-18.3-16.4-30.7c0-20.3 16.5-36.8 36.8-36.8zm-152 6.4L256 229.3l35.2-46.9c4.1-5.5 11.3-7.8 17.9-5.6s10.9 8.3 10.9 15.2l0 96c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48-19.2 25.6c-3 4-7.8 6.4-12.8 6.4s-9.8-2.4-12.8-6.4L224 240l0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-96c0-6.9 4.4-13 10.9-15.2s13.7 .1 17.9 5.6z"/>*/}
            {/*    </svg>*/}
            {/*</div>*/}

        </div>
    );
};
export default Signup;
