import { JSX, useState, ReactNode } from "react";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import React from "react";

interface FacebookProfileData {
  name: string;
  picture: {
    data: {
      url: string;
    };
  };
}

interface LoginSocialFacebookProps {
  appId: string;
  onResolve: (response: any) => void;
  onReject: (error: any) => void;
  children: ReactNode;
}

const FacebookLoginWrapper = (props: LoginSocialFacebookProps) => (
  <LoginSocialFacebook {...props} />
);

export default function FacebookLogin({
  gamesession,
  game,
  coins,
  name,
  deeplink,
}): JSX.Element {
  const [profile, setProfile] = useState<FacebookProfileData | null>(null);
  const appId = process.env.FACEBOOK_APP_ID;
  const navigate = useNavigate();

  async function handleSendAccessToken(token: string) {
    try {
      const res = await fetch(`${process.env.API_URL}/auth/facebook`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY as string,
        },
        body: JSON.stringify({ token, gamesession, game, coins }),
      });

      if (res.status === 200) {
        const data = await res.json();

        await Cookies.set("c8_session_token", data.token, {
          expires: 365,
          secure: false,
          sameSite: "strict",
        });
        if (deeplink === null) {
          window.location.href = "/account";
        } else {
          window.location.href = deeplink;
        }
      } else {
        throw new Error("Failed to authenticate with Facebook");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return (
    <div>
      {!profile ? (
        <FacebookLoginWrapper
          appId={appId as string}
          onResolve={(response: any) => {
            setProfile(response.data as FacebookProfileData);
            handleSendAccessToken(response.data.accessToken);
          }}
          onReject={(error: any) => {
            console.log(error);
          }}
        >
          <div className="w-full md:w-[600px]">
            <button
              className="mt-[5px] font-google text-white !normal-case 
              text-[20px] flex flex-row justify-center 
            items-center bg-[#1877F2] h-[50px] w-full cursor-pointer gap-2"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="34"
                  height="34"
                >
                  <rect
                    width="34"
                    height="34"
                    fill="#4267B2"
                    stroke="white"
                    stroke-width="1"
                  />
                  <path
                    d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24h11.504v-9.294H9.904v-3.62h3.925V8.408c0-4.006 2.348-6.214 6.021-6.214 1.757 0 3.569.146 3.569.146v3.95h-2.008c-1.976 0-2.58 1.545-2.58 3.12v3.204h4.5l-.72 3.62h-3.78V24h7.146c.732 0 1.325-.593 1.325-1.325V1.325C24 .593 23.407 0 22.675 0z"
                    fill="white"
                  />
                </svg>
              </div>
              <span className="text-[16px]">{name}</span>
            </button>
          </div>
        </FacebookLoginWrapper>
      ) : null}

      {profile && (
        <div className="flex flex-row justify-start items-center mt-[10px] gap-2">
          <h1>{profile.name}</h1>
          <img src={profile.picture.data.url} alt="Profile" />
        </div>
      )}
    </div>
  );
}
